$fonts-sans: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;

$colors: (
    "text": #272727,
    "white": #FFFFFF,
    "black": #000000,
    "gray-81": #303030,
    "button": #0099FF,
    "button-dark": #005C99,
    "button-disabled": #555555,
);

$breakpoint-desktop: 600px;

body {
    padding: 0;
    margin: 0;
    background-color: map-get( $colors, "gray-81" );
}

header {
    background-color: #C0C0C0;
    padding: 1rem;
}

ul.nav {

    padding-left: 0;
    list-style-type: none;

    li {
        display: inline-block;
    }

    li:not(:last-child) {
        margin-right: 1rem;
    }

}

.body {
    padding: 0 0 2rem 0;
    background-color: map-get( $colors, "white" );
}

a.no-underline {
    text-decoration: none;
}

button,
.button {

    display: inline-block;
    padding: 10px 20px;
    background-color: map-get( $colors, "button" );
    color: map-get( $colors, "white" );
    border: none;
    border-radius: 10px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        background-color: map-get( $colors, "button-dark" );
    }

    &:disabled {

        background-color: map-get( $colors, "button-disabled" );
        cursor: default;

        &:hover {
            background-color: map-get( $colors, "button-disabled" );
        }
    }

}
