body, input, textarea {
    font-family: $fonts-sans;
    line-height: 1.333rem;
}

.text-align-center {
	text-align: center !important;
}

hr { background-color: #7F7F7F; }
